export default {
  data() {
    return {
      allTime : {},
      monthly : {},
      weekly  : {},
    };
  },
  mounted() {
    this.init();
  },
  methods : {
    async init() {
      this.getAllTime();
      this.getMonthly();
      this.getWeekly();
    },
    getAllTime(page = 1) {
      this.$http.get('/api/leaderboards/all_time',
        {
          params : {
            page    : page,
            perPage : 10,
          },
        })
        .then((res) => {
          const o = res.data,
            n = {};
          Object.keys(o)
            .forEach((k) => {
              n[this.camelToSake(k)] = o[k];
            });

          this.allTime = n;
        })
        .catch(() => {
          this.allTime = {};
        });
    },
    getMonthly(page = 1) {
      this.$http.get('/api/leaderboards/monthly',
        {
          params : {
            page    : page,
            perPage : 10,
          },
        })
        .then((res) => {
          const o = res.data,
            n = {};
          Object.keys(o)
            .forEach((k) => {
              n[this.camelToSake(k)] = o[k];
            });

          this.monthly = n;
        })
        .catch(() => {
          this.monthly = {};
        });
    },
    getWeekly(page = 1) {
      this.$http.get('/api/leaderboards/weekly',
        {
          params : {
            page    : page,
            perPage : 10,
          },
        })
        .then((res) => {
          const o = res.data,
            n = {};
          Object.keys(o)
            .forEach((k) => {
              n[this.camelToSake(k)] = o[k];
            });

          this.weekly = n;
        })
        .catch(() => {
          this.weekly = {};
        });
    },
    camelToSake(string) {
      return string.replace(/[\w]([A-Z])/g, (m) => {
        return m[0] + '_' + m[1];
      }).toLowerCase();
    },
  },
};