<template>
  <div class="leaderboard-widget">
    <div class="header">
      <h3 class="text-center">
        <router-link to="/browse/leaderboard">LEADERBOARD</router-link>
      </h3>
    </div>
    <div class="leaderboard-tabs">
      <b-tabs content-class="" fill>
        <b-tab title="ALL TIME" active>
          <div class="content">
            <div class="items"
                 v-if="allTime.data && allTime.data.length > 0">
              <leaderboard-item v-for="(item, index ) in allTime.data"
                                :type="'all'"
                                :display="'widget'"
                                :item="item"
                                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
        <b-tab title="MONTHLY">
          <div class="content">
            <div class="items"
                 v-if="monthly.data && monthly.data.length > 0">
              <leaderboard-item v-for="(item, index ) in monthly.data"
                                :type="'monthly'"
                                :display="'widget'"
                                :item="item"
                                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
        <b-tab title="WEEKLY">
          <div class="content">
            <div class="items"
                 v-if="weekly.data && weekly.data.length > 0">
              <leaderboard-item v-for="(item, index ) in weekly.data"
                                :type="'weekly'"
                                :display="'widget'"
                                :item="item"
                                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>
<style lang="scss">
  @import "../../../assets/scss/components/user/common/leaderboard-widget";
</style>
<script>
  import LeaderboardMixin from '@/mixins/LeaderboardMixin';
  const LeaderboardItem = () =>
  import('@/components/user/leaderboard/LeaderboardItem.vue');


  export default {
    data() {
      return {

      }
    },
    components : {
      LeaderboardItem,
    },
    mixins : [
      LeaderboardMixin,
    ],
  }

</script>